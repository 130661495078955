<template>
  <div class="auth-wrapper auth-v1" @keyup.enter="login">
    <div class="auth-bg"></div>
    <div class="auth-header mt-5">
      <v-img class="mx-auto mb-5" :src="require('@/assets/logos/rmc.png')" width="100" />
      <h3>GOVERNMENT OF JHARKHAND</h3>
      <h3>JHARKHAND SPACE APPLICATION CENTER</h3>
      <h3>CIVIC LAND AREA INFRASTRUCTURE MANAGEMENT SYSTEM</h3>
    </div>
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <div class="text-2xl font-weight-semibold">LOGIN AS ADMINSTRATOR</div>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-text-field
              v-model="form.email"
              outlined
              label="Email"
              :rules="[v => !!v || 'Email is required']"
            ></v-text-field>

            <v-text-field
              class="mt-2"
              v-model="form.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :rules="[v => !!v || 'Password is required']"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox v-model="form.rememberMe" label="Remember Me" hide-details class="me-3 mt-1"> </v-checkbox>
            </div>

            <v-btn block color="primary" class="mt-6" @click="login"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data() {
    return {
      isFormValid: true,
      isPasswordVisible: false,
      form: {
        email: '',
        password: '',
        rememberMe: false,
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    async login() {
      try {
        this.$loader(true)
        await this.$refs.form.validate()
        if (!this.isFormValid) return
        await this.$store.dispatch('auth/login', this.form)
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
